footer{
    background-color: black;
    color: #fff;
    padding: 20px;
    font-family: aktiv-grotesk-thin;
    font-size: 20px;
    letter-spacing: .02em;

    // For a computer
    @media (min-width: 740px) {
        width: 80%;
        display: grid; //responsible for making both the left and right columns take the same proportion of the scren
        grid-template-columns: 0.35fr 0.3fr 0.35fr; //seet the number of columns as 2 
        padding: 65px 10%;
        align-items: baseline; 
        font-size: 25px;
        line-height: 0.80em;
    }
}
.right-column > h3{
    font: 24px trump-gothic-pro;
    font-weight: 700;
    letter-spacing: .2px;
    margin: 20px 0px 34px;
    line-height: 1.8em;
    text-transform: uppercase;

    // For a computer
    @media (min-width: 740px) {
        font-size: 35px;
        margin: 0px 0px 34px;
        letter-spacing: .25em;
        width: max-content;
    }
}
.left-column{

    // For a computer
    @media (min-width: 740px) {

    }
}
.links{
    padding: 17px 0px 34px 0px;
}
.links > a{
    color: white;
    line-height: 1.8em;
    text-transform: uppercase;
    font-size: 24px;
    font-family: trump-gothic-pro;
    text-decoration: none;
    font-weight: bold;
    padding: 0px 0px 1.2px;
    border-bottom: 1px solid rgba(255,255,255,.3);
    transition: 0.2s ease-in;

    // Hover effect
    &:hover {
        color: rgba(255, 255, 255, 0.411);
    }
    // For a computer
    @media (min-width: 740px) {
        font-size: 35px;
        letter-spacing: .25em;
        flex-grow: 1;
    }
}
.socials{
    padding-bottom: 17px;
}
div > .fa{ //responsible for the social media logos
    padding: 10px;
    width: 16px;
    height: 16px; //same height and widht are required for a circle
    text-align: center;
    text-decoration: none;
    border-radius: 100%;
    justify-content: center;
    font-size: 16.88px; //font size must be the same as height/width to fit well
    background: white;
    color: black;
    margin: 5px;

    // Hover effect
    &:hover {
        background-color: rgba(255, 255, 255, 0.736);
    }

    // For a computer
    @media (min-width: 740px) {
        width: 26px;
        height: 26px;
        font-size: 26px;
    }
}
#facebook{
    margin-left: 0px; //gets rid of an automatic adjustment that we beed for every social media but facebook
}