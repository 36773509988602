.carDisplay {
  color: white;
  flex-direction: row;
  display: flex;
}

.miniCar {
  margin: auto auto;
  .team-name {
    font-size: 15px;
  }
}

.link {
  text-decoration: none;
  color: white;
}

.carDetails {
  text-align: center;
  margin: auto;
}

@media (min-width: 768px) {
  .carDisplay {
    margin: 40px auto 0 auto;
    width: 50%;
  }
  .carDetails {
    width: 50%;
  }
  .miniCar {
    margin: auto auto;
    .team-name {
      font-size: 20px;
    }
  }
}
