// constant for how long the animation should take.
$ANIMATION_DURATION: 3s;

// all components in the svg should follow these styles
.spinner-svg {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: none;
  stroke-width: 4;
  overflow: visible;
}

.spinner-svg--tires {
  stroke-width: 3.77858;
}

// animation stuff! see animations at the bottom of the file
.spinner-svg path:not(.spinner-svg--woosh), .spinner-svg circle {
  animation: car $ANIMATION_DURATION ease-in-out infinite;
}

.spinner-svg--woosh {
  animation: woosh $ANIMATION_DURATION ease-in-out infinite;
  opacity: 0;
}
.spinner-svg--woosh:nth-child(1) {
  animation-delay: 0;
}
.spinner-svg--woosh:nth-child(2) {
  animation-delay: 0.1s;
}
.spinner-svg--woosh:nth-child(3) {
  animation-delay: 0.2s;
}

.spinner-svg--bounce_animation {
  animation: bounce 0.4s ease-in-out infinite;
}
/**
  The animation of the car in and out. Intended to be looped forever. Varies slightly in timing for different elements based on delays
*/
@keyframes car {
  
  // start off inivisible and moved to the left
  0% {
    opacity: 0;
    transform: translateX(-20%);
    filter: blur(2px);
  }
  10% {
    opacity: 0;
    transform: translateX(-20%);
    filter: blur(2px);
  }

  30% {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0px);
  }

  70% {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0px);
  }

  90% {
    opacity: 0;
    transform: translateX(20%);
    filter: blur(2px);
  }
  100% {
    opacity: 0;
    transform: translateX(20%);
    filter: blur(2px);
  }

}

// particles at the back coming in and out
@keyframes woosh {

  0% {
    opacity: 0;
    transform: translateX(0)
  }
  40% {
    opacity: 0;
    transform: translateX(0)
  }

  50% {
    opacity: 0.5;
    transform: translateX(-5%);
  }
  55% {
    opacity: 0.5;
    transform: translateX(-5%);
  }

  65% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 0;
    transform: translateX(-10%);
  }

}

// whole car bouncing up and down slightly
@keyframes bounce {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translate(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}