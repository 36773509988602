@import "../Master.scss";

// use css variables to more easily adjust things like the navbar height that affect multiple styles depending on the device being used
html {
  background: #4F0409;
}

.firstSection {
  background-color: #4F0409;
  min-height: 30vh;
  align-items: center;
  display: flex;

  .text {
    width: 100%;
    text-align: center;
  }

  .landing-car {
    margin-left: 15%;
    width: 80%;
    mix-blend-mode: color-burn;
    position: absolute;
  }

  @media screen and (min-width: 500px) {
    min-height: 40vh;
  }

  @media screen and (min-width: 800px) {
    height: 95vh;
  }

  @media screen and (min-width: 1195px) {
    height: 85vh; //navbar gets bigger
  }
}

.secondSection {
  background-color: black;
   height: 100vh;
   width: 100vw;
   
}

.thirdSection {
  background-color: #4F0409;
  height: 85vh;
  width: 100vw;
  .tabSwitcher {
    position:absolute;
    margin-left: 42%;
    margin-top: 20%;
    width: 50%;
        
    //expands from half the screen to full when not on mobile
    @media (min-width: 600px) {
      margin-left: 62%;
      margin-top: 10%;
      width: 30%;
    }
  }
}

.fourthSection {
  background-color: black;
   height: 200vh;
   width: 100vw;
}
