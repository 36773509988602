@import "../Master.scss";

.stats {
  .whoWeAre {
    padding: 0 20px;
    text-align: center;
  }
  .piechart-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.teamPic {
  width: 100%;
  margin: 25px auto 0 auto;
  display: flex;
}

.card-container {
  flex-direction: column;
  display: flex;
  .card {
    width: 80%;
    margin: 10px 10%;
    text-align: center;
  }
  margin-bottom: 50px;
}

@media (min-width: 1024px) {
  .stats {
    margin: auto;
    flex-direction: row;
    display: flex;
    width: 90%;
    .whoWeAre {
      width: 50%;
    }
  }
  .card-container {
    width: 90%;
    margin: auto;
    flex-direction: row;
    .card {
      margin: 10px;
    }
  }
  .teamPic {
    width: 70%;
    margin: 50px auto 0 auto;
  }
}

@media (min-width: 1430px) {
  .stats {
    width: 70%;
  }
  .card-container {
    width: 70%;
  }
}
