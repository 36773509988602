@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque&family=Pathway+Gothic+One&display=swap");

// the fonts from the MFE website
@font-face {
  font-family: trump-gothic-pro;
  src: url(https://use.typekit.net/af/db90e9/00000000000000007735a57d/30/l?subset_id=2&fvd=n4&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/db90e9/00000000000000007735a57d/30/d?subset_id=2&fvd=n4&v=3)
      format("woff"),
    url(https://use.typekit.net/af/db90e9/00000000000000007735a57d/30/a?subset_id=2&fvd=n4&v=3)
      format("opentype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: trump-gothic-pro;
  src: url(https://use.typekit.net/af/49d57b/00000000000000007735a578/30/l?subset_id=2&fvd=n5&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/49d57b/00000000000000007735a578/30/d?subset_id=2&fvd=n5&v=3)
      format("woff"),
    url(https://use.typekit.net/af/49d57b/00000000000000007735a578/30/a?subset_id=2&fvd=n5&v=3)
      format("opentype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: trump-gothic-pro;
  src: url(https://use.typekit.net/af/b21551/00000000000000007735a564/30/l?subset_id=2&fvd=n7&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/b21551/00000000000000007735a564/30/d?subset_id=2&fvd=n7&v=3)
      format("woff"),
    url(https://use.typekit.net/af/b21551/00000000000000007735a564/30/a?subset_id=2&fvd=n7&v=3)
      format("opentype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: aktiv-grotesk-thin;
  src: url(https://use.typekit.net/af/099172/000000000000000077359d41/30/l?subset_id=2&fvd=n2&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/099172/000000000000000077359d41/30/d?subset_id=2&fvd=n2&v=3)
      format("woff"),
    url(https://use.typekit.net/af/099172/000000000000000077359d41/30/a?subset_id=2&fvd=n2&v=3)
      format("opentype");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: aktiv-grotesk-thin;
  src: url(https://use.typekit.net/af/647e79/000000000000000077359d44/30/l?subset_id=2&fvd=i2&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/647e79/000000000000000077359d44/30/d?subset_id=2&fvd=i2&v=3)
      format("woff"),
    url(https://use.typekit.net/af/647e79/000000000000000077359d44/30/a?subset_id=2&fvd=i2&v=3)
      format("opentype");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}

$header-font: "Darker Grotesque";
$body-font: "Darker Grotesque";
$body-color: white;
$white: #ffffff;
$header1-color: white;
$header2-color: #ffcbce;
$header3-color: #e3caca;
$line-color: #a6a6a6;
$footer-line-color: #a60007;

.header1 {
  font-family: $header-font;
  color: $header1-color;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2em;
  text-transform: uppercase;
}

.header2 {
  font-family: $header-font;
  font-weight: 400;
  font-size: 36px;
  letter-spacing: 0.1em;
  line-height: 1.3em;
  text-transform: uppercase;
  color: $header2-color;
}

.header3 {
  font-family: $body-font;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  padding: 15px;
  color: white;
  line-height: 1.3em;
}

.body {
  font-family: $body-font;
  font-weight: 200;
  font-size: 20px;
  letter-spacing: 0.02em;
  line-height: 1.25em;
  color: $body-color;
  white-space: pre-line;
}

.page-title {
  font-family: $body-font;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  padding: 15px;
  color: white;
}

.subheading {
  font-family: $body-font;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  padding: 15px;
  color: white;
}

//properties of horizontal line divider
.divider {
  box-sizing: border-box;
  width: 80%;
  //add spacing between the horizontal line and other elements
  margin: 16px auto;
  height: 3px;
  //center the horizontal line
  display: flex;
  justify-content: center;
  //add a white background
  background-color: white;
}

//if tablet
@media (min-width: 500px) {
  .header1 {
    font-size: 50px;
  }

  .subheading {
    font-size: 20px;
  }
}


//if desktop
@media (min-width: 1024px) {
  .divider {
    width: 60%;
  }

  .header1 {
    font-size: 80px;
  }

  .subheading {
    font-size: 40px;
  }
}
