
.flexcontainer{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 10px;
    margin-left:  10px;
}

.instagram{
    max-width: 20px;
    max-height: 20px;
   
    //makes the logo white
    filter:  invert(100%) sepia(10%) saturate(7458%) hue-rotate(278deg) brightness(116%) contrast(109%);

    //tablet
    @media (min-width: 350px) {
        //expanding size
        max-width: 25px;
        max-height: 25px;

        //spacing out
        margin-top: 5px;

    }

    //comptuer
    @media (min-width: 740px) {
        //expanding size
        max-width: 30px;
        max-height: 30px;

        //spacing out
        margin-top: 10px;
    }
}
.facebook{
    max-width: 20px;
    max-height: 20px;
    //this makes the color white
    filter:  invert(100%) sepia(10%) saturate(7458%) hue-rotate(278deg) brightness(116%) contrast(109%);

    //tablet
    @media (min-width: 350px) {
        //expanding size
        max-width: 25px;
        max-height: 25px;

        //spacing out
        margin-top: 5px;

    }

    //comptuer
    @media (min-width: 740px) {
        //expanding size
        max-width: 30px;
        max-height: 30px;

        //spacing out
        margin-top: 10px;

    }
    
    
}

.linkedin{
    max-width: 20px;
    max-height: 20px;
  

   //makes the logo  white
    filter:  invert(100%) sepia(10%) saturate(7458%) hue-rotate(278deg) brightness(116%) contrast(109%);

    //tablet
    @media (min-width: 350px) {
        //expanding size
       
        max-width: 25px;
        max-height: 25px;

    }

    //comptuer
    @media (min-width: 740px) {
        //expanding size
       
        max-width: 30px;
        max-height: 30px;

    }
}


//when hovering make it grey
.linkedin:hover{
    
    filter: invert(53%) sepia(6%) saturate(6%) hue-rotate(23deg) brightness(94%) contrast(89%);
}

.facebook:hover{
    filter: invert(53%) sepia(6%) saturate(6%) hue-rotate(23deg) brightness(94%) contrast(89%);

}

.instagram:hover{
    filter: invert(53%) sepia(6%) saturate(6%) hue-rotate(23deg) brightness(94%) contrast(89%);

}





