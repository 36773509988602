@import '../Master.scss';

.tabswitcher{
    font-family: $body-font;
    font-size: large;
    overflow: hidden;

    //on small mobile moves slightly upwards
    @media (max-width: 250px) {
        font-size: medium;
    }
}


//for the heading
.tabs{
    color: grey;
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
    font-size: 30px;
    text-align: center;
    flex-wrap: wrap;

    @media (max-width: 500px) {
        font-size: 25px;
    }
    
}

//for the indivual things
//fo rthe one that is not active
.tab {
    display: flex;
    flex-grow: 1;
    margin-left: 5%;

    opacity: .7;

    //if smaller than 350 pixels will give more space as a column
    @media (max-width: 350px) {
        margin-bottom: 2%;
    }
}

//active tab
.active{
    display: flex;
    flex-grow:1;
    margin-left: 5%;

    color: white;
    text-decoration: underline;

    @media (max-width: 350px) {
        
        margin-bottom: 2%;
        
    }
}

//for the information
.info{
    
    color: white;
    text-align: justify;
}