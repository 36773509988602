@import "../Master.scss";

//properties for the whole page
.team-content {
  color: white;
  // add a white background
  // add padding for the whole page
  box-sizing: border-box;
  padding: 20px 30px;
  //elements centered in page
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr;
}

//properties of grids with 3 columns
.team-members3-grid {
  padding: 15px;
  display: grid;
  //max width defined so that the spacing between images stay constant even for very large screens
  max-width: 1560px;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;

  // adapt the grid to a larger screen, it should have three columns instead of one
  @media (min-width: 740px) {
    grid-template-columns: 1fr 1fr 1fr;
    // two rows for the cards
    // make the grid fill horizontally first
    grid-auto-flow: row;
    //add spacing between images and texts
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
}

//properties of grids with 2 columns
.team-members2-grid {
  // adapt the grid to a larger screen, it should have three columns instead of one
  @media (min-width: 740px) {
    grid-template-columns: 1fr 1fr;
  }
}

.fade {
  transition: opacity 1s;
}

.hidden {
  opacity: 0;
}

.spinner-container {
  margin: auto;
  width: 50%;
  margin-top: 20vh;
  right: 50%;
  height: 25vh;
}
