.item {
  background-color: #dd363650;
  margin-bottom: 5px;
  padding: 10px 20px;
  border-radius: 15px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
