// these styles implement the current design and core functionality of the carousel

.carousel-container {
  position: relative;
  display: block;
  width: 100%;
  height: 340px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  // add scroll snapping so the carousel keeps an image centered
  scroll-snap-type: x mandatory;

  .carousel-img {
    height: 100%;

    // make sure it snaps to the centre when possible
    scroll-snap-align: center;
  }

}

.carousel {
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 340px;
}

// the next and previous buttons
.carousel-button {
  background: rgba(0, 0, 0, 0.12);
  transition: background 200ms cubic-bezier(.25,.46,.45,.94);
  border: none;
  color: white;
  font-size: 14px;
  line-height: 40px;
  padding: 10px;

  // position the buttons
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  // align the image inside
  display: flex;
  justify-content: center;
  align-items: center;

  // position each button
  &.carousel-button--next {
    right: 0;
  }
  &.carousel-button--previous {
    left: 0;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

}

// all the following styles are a new design