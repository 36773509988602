@import "../Master.scss";

.piecharthover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding-top: 0.1px;
  padding-bottom: 0.5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
