/* Desktop view */

.navbar-desktop {
  background-color: black;
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 0px;
  padding-left: 31px;
  padding-right: 31px;
}

.logo-desktop {
  width: 185px;
  padding: 14.5px 9px;
  display: inline;
}

.hamburger-desktop {
  display: none;
}

.navbar-links-desktop {
  display: inline;
  float: right;
  padding: 30px 0px 0px;
}

.link-desktop {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  font-size: 15px;
  letter-spacing: 0.2em;
  margin: 0 1.5em;
  padding: 0.618em 0;
}

.link-contact-us {
  padding: 12px 18px;
  border: 2px solid #ffffff;
}

.navbar-close-desktop {
  display: none;
}

/* Mobile view */
@media (max-width: 1195px) {
  .navbar-mobile {
    background-color: black;
    overflow: hidden;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .logo-mobile {
    width: 80px;
    padding: 8px;
    display: inline;
  }

  .navbar-links-mobile {
    display: none;

    &.open {
      display: block;
    }
  }

  .navbar-pages-mobile {
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    right: 0;
    width: calc(100% - 60px);
    padding: 36px;
    overflow: auto;
    transition: transform 350ms cubic-bezier(0.55, 0, 0.1, 1);
    background-color: #191606;
  }

  .mobile-navbar-close {
    display: inline;
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    background-color: #191606;
  }

  .mobile-navbar-close .close-button {
    position: absolute;
    background-color: transparent; //change this to background color
    color: #ffffff;
    //left: 0;
    //display: block;
    width: 60px;
    height: 60px;
    border: 0;
    padding: 22px;
    line-height: 0;
  }

  .navbar-close-icon {
    width: 24px;
    height: 24px;
    margin: 18px;
  }

  .link {
    color: #ffffff;
    float: right;
    line-height: 1.8em;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    font-style: normal;
    font-size: 13px;
    text-decoration: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 0.618em;
    padding-left: 0px;
    text-align: left;
    border: none;
  }

  .hamburger {
    display: inline-block;
    float: right;
    line-height: 0;
    margin-top: 12px;
  }

  .hamburger .hamburger-button {
    position: absolute;
    width: 40px;
    height: 34px;
    background-color: transparent;
    text-align: center;
    border: 0;
    padding: 8px;
  }

  .hamburger-icon {
    width: 40px;
    height: 34px;
  }
}
