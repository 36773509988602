@import "../Master.scss";
//properties of the text for every person's name
.team-name {
    font-family: $body-font;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
}

//properties of the text describing every person's position
.team-position {
    font-family: $body-font;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.team-image {
    max-width: 500px;
    width: 75%;
    max-height: 500px;
    border-radius: 10px;
    //center the image


    display: flex;
    margin: auto;
    transition: width 0.5s;
    //80% on hover
    &:hover {
        
        width: 80%;
    }
}